import Tag from "components/Tag/Tag";
import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

function SchedulesTable(props) {
  function getCustomer(id) {
    return props.customers.find((customer) => customer._id === id);
  }

  function getProject(id) {
    if (!props.projects) return;
    const project = props.projects.find((project) => project._id === id);
    return project.name.replace(/\s\s+/g, " ");
  }

  function getAddress(id) {
    if (!props.adresses) return "";
    const adress = props.adresses.find((address) => address._id === id);
    if (adress?.address) return adress.address.replace(/\s\s+/g, " ");
    return "";
  }
  function getScheduleTagColor(schedule) {
    if (schedule.status === "PA") return "var(--yellow)";
    else if (schedule.status === "RJ") return "#000000";
    let date = new Date();
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 0) {
      date.setDate(date.getDate() - 4);
    } else if (dayOfWeek === 6) {
      date.setDate(date.getDate() - 3);
    } else if (dayOfWeek === 1) {
      date.setDate(date.getDate() - 4);
    } else {
      date.setDate(date.getDate() - 2);
    }
    const approvedDate = schedule.log?.find?.(logInList=>logInList?.observation?.activity === 'AP')?.date || schedule.createdAt
    const isServiceOrderLate =
      new Date(approvedDate) < date && new Date(schedule.date) < date;
    return schedule.serviceOrder
      ? "var(--success)"
      : !schedule.serviceOrder && isServiceOrderLate
      ? "var(--warning)"
      : undefined;
  }

  const getSchedules = (date, userSchedules) => {
    let schedules = [];
    schedules = userSchedules.filter((schedule) => {
      return props.status === "all"
        ? schedule.date.split("T")[0] === date.fullDate.split("T")[0]
        : schedule.date.split("T")[0] === date.fullDate.split("T")[0] &&
            schedule.status === props.status;
    });
    return schedules || [];
  };

  return (
    <>
      <Table hidden={!props.columns.length}>
        <thead>
          <tr>
            <th
              style={{
                zIndex: "3",
                position: "sticky",
                left: "0",
                top: "0",
                background: "white",
              }}
            >
              Nome
            </th>
            {props.columns.map((date, index) => {
              return (
                <th
                  key={index}
                  style={{
                    zIndex: "3",
                    position: "sticky",
                    top: "0",
                    background: "white",
                  }}
                >
                  <Row style={{ justifyContent: "center" }}>{date.weekday}</Row>
                  <Row style={{ justifyContent: "center" }}>{date.day}</Row>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody style={{ height: "70vh" }}>
          {props.users.map((user) => {
            return (
              <tr key={user._id}>
                <td
                  style={{
                    zIndex: "2",
                    position: "sticky",
                    left: "0",
                    background: "white",
                    margin: "0px 0px 0px 0px",
                    height: "15vh",
                    verticalAlign: "middle",
                  }}
                >
                  {user.fullName}
                </td>
                {props.columns.map((date, columnsIndex) => {
                  let schedules = getSchedules(date, user.schedules);
                  if (schedules?.length > 0) {
                    return (
                      <td key={columnsIndex}>
                        {schedules.map((schedule, scheduleIndex) => {
                          if (scheduleIndex < 2) {
                            return (
                              <Row
                                id={`schedule-${schedule._id}-row-${scheduleIndex}`}
                                style={{ justifyContent: "center" }}
                                key={scheduleIndex}
                                onClick={() => {
                                  props.handleScheduleModal(true);
                                  props.handleSelectSchedule(schedule);
                                }}
                              >
                                <Tag
                                  backgroundColor={getScheduleTagColor(
                                    schedule
                                  )}
                                >
                                  {getCustomer(schedule.customer).shortName}
                                </Tag>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`schedule-${schedule._id}-row-${scheduleIndex}`}
                                  style={{ pointerEvents: "none" }}
                                >
                                  <div
                                    style={{
                                      padding: "1vh 2vh 1vh 2vh",
                                    }}
                                  >
                                    <Row
                                      className="pb-2"
                                      style={{ textAlign: "left" }}
                                    >
                                      {getProject(schedule.project) ??
                                        "Sem projeto definido"}
                                    </Row>
                                    <Row
                                      className="pb-2"
                                      style={{ textAlign: "left" }}
                                    >
                                      {getAddress(schedule?.address) ??
                                        "Sem Endereço definido"}
                                    </Row>
                                    <Row style={{ textAlign: "left" }}>
                                      Início {schedule.timeFrom}
                                    </Row>
                                    <Row
                                      className="pb-2"
                                      style={{ textAlign: "left" }}
                                    >
                                      Fim {schedule.timeTo}
                                    </Row>
                                  </div>
                                </UncontrolledTooltip>
                              </Row>
                            );
                          } else {
                            return (
                              <Row
                                style={{ justifyContent: "flex-end" }}
                                key={scheduleIndex}
                              >
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button-circular"
                                    color="primary"
                                    style={{
                                      borderRadius: "20px",
                                      fontSize: "0.85vh",
                                      width: "2vh",
                                      height: "2vh",
                                      color: "white",
                                      padding: "0.05vh 0vh 0vh 0.08vh",
                                    }}
                                  >
                                    <i className="fas fa-solid fa-plus"></i>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    {schedules
                                      .splice(2)
                                      .map((schedule, dropdownIndex) => {
                                        return (
                                          <DropdownItem key={dropdownIndex}>
                                            <Row
                                              id={`schedule-${schedule._id}-row-${scheduleIndex}-dropdown${dropdownIndex}`}
                                              style={{
                                                justifyContent: "center",
                                              }}
                                              onClick={() => {
                                                props.handleScheduleModal(true);
                                                props.handleSelectSchedule(
                                                  schedule
                                                );
                                              }}
                                            >
                                              <Tag
                                                backgroundColor={getScheduleTagColor(
                                                  schedule
                                                )}
                                              >
                                                {
                                                  getCustomer(schedule.customer)
                                                    .shortName
                                                }
                                              </Tag>
                                              <UncontrolledTooltip
                                                delay={0}
                                                target={`schedule-${schedule._id}-row-${scheduleIndex}-dropdown${dropdownIndex}`}
                                              >
                                                <div
                                                  style={{
                                                    padding: "1vh 2vh 1vh 2vh",
                                                  }}
                                                >
                                                  <Row
                                                    className="pb-2"
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {getProject(
                                                      schedule.project
                                                    ) ?? "Sem projeto definido"}
                                                  </Row>
                                                  <Row
                                                    className="pb-2"
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {getAddress(
                                                      schedule.address
                                                    ) ??
                                                      "Sem Endereço definido"}
                                                  </Row>
                                                  <Row
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    Início {schedule.timeFrom}
                                                  </Row>
                                                  <Row
                                                    className="pb-2"
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    Fim {schedule.timeTo}
                                                  </Row>
                                                </div>
                                              </UncontrolledTooltip>
                                            </Row>
                                          </DropdownItem>
                                        );
                                      })}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </Row>
                            );
                          }
                        })}
                      </td>
                    );
                  } else {
                    return (
                      <td key={columnsIndex}>
                        <div style={{ width: "12vh" }}></div>
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
export default SchedulesTable;
